.resume {
  min-height: 100%;
  max-width: 600px;
  margin: auto;
}

.resume h2 {
  font-size: xxx-large;
  text-align: center;
}

.contact {
  text-align: center;
}

.skills ul, .experience ul, .education ul, .awards ul {
  display: block;
  list-style-type: disc;
  padding-inline-start: 40px;
}

ul.languages {
  padding-top: 5px;
}