html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: 'Helvetica Neue', arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}

.header {
  left: 0;
  top: 0;
  width: 100%;
  height: 56px;
  padding: 0;
  background: #673ab7;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.header h1 {
  float: left;
  margin: 0;
  padding: 0 15px;
  line-height: 56px;
  font-weight: 400;
  color: #fff;
}

.header nav {
  font-size: 100%;
  text-align: center;
}

.header nav a {
  display: inline-block;
  height: 56px;
  line-height: 56px;
  padding: 0 15px;
  min-width: 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  text-decoration: none;
  color: #fff;
  will-change: background-color;
}

.header nav a:hover,
.header nav a:active {
  background: rgba(0, 0, 0, 0.2);
}

.header nav a.active {
  background: rgba(0, 0, 0, 0.4);
}

.content {
  margin: 0 15px;
}

.content h2 {
  padding: 20px 0;
}

.content h3 {
  font-size: large;
  font-weight: bold;
  line-height: 25px;
  padding: 10px 0;
}

p {
  padding-bottom: 10px;
}

b {
  font-weight: bold;
}

li {
  padding-bottom: 5px;
}