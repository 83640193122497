.minis h2 {
  font-size: xxx-large;
  text-align: center;
}

.minis {
  min-height: 100%;
  max-width: 600px;
  margin: auto;
  text-align: center;
}
